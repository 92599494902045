import ApiService from "./ApiService";

const GET_MEMBERS = "api/v1/member/list/";
const GET_SPECIFIC_MEMBER = "api/v1/member/";
const GET_SHORTEN_MEMBER = "api/v1/member/<pk>/shorten-member";
const ADD_MEMBER = "api/v1/member/add/";
const VALIDATE_MEMBER = "api/v1/member/verify-add/";
const DELETE_MEMBERS = "api/v1/member/delete/";
const UPDATE_MEMBER = "api/v1/member/<pk>/update/";
const ARCHIVE_MEMBERS = "api/v1/member/archive/";
const SEND_PRO_MEMBER_EMAIL = "api/v1/member/<pk>/send-email";

const GET_NATIONALITIES = "api/v1/nationality/list/";
const GET_SPOKEN_LANGUAGES = "api/v1/spoken-languages";
const GET_BAILLIAGES = "api/v1/bailliage";
const GET_SPECIFIC_BAILLIAGE = "api/v1/bailliage/<pk>";
const LIST_BAILLIAGES = "api/v1/bailliage/list/";
const GET_CHILDREN_BAILLIAGES = "api/v1/bailliage/<pk>/get_by_parent";
const GET_CHILDREN_BAILLIAGES_MULTIPLE = "api/v1/bailliage/get-by-parents/";
const GET_FUNCTIONS = "api/v1/function/list/";
const GET_MARITAL_STATUSES = "api/v1/marital-status/list/";
const GET_MEDALS = "api/v1/medal/list/";
const GET_COMMANDERS = "api/v1/commander/list/";
const GET_ELIGIBLE_COMMANDERS = "api/v1/member/<pk>/get-eligible-commanders";
const GET_ACTIVITY_SECTORS = "api/v1/activity-sector/list/";
const GET_TITLES = "api/v1/title/list/";
const GET_INSTITUTION_TYPES = "api/v1/institution-type/list/";
const GET_GRADES = "api/v1/grade/list/";

const GET_DUE_TYPES = "api/v1/cotisation-type/list/";
const GET_PRICE_CATEGORIES = "api/v1/cotisation-price-category/list/";
const GET_PRICE_CATEGORIES_FILTERED =
    "api/v1/cotisation-price-category/list-add-member/";
const GET_DUES = "api/v1/cotisation/list/";
const GET_SPECIFIC_DUE_TYPES = "api/v1/cotisation-type/list-specific/";
const ADD_DUE = "api/v1/cotisation/add/";
const MANAGE_DUE = "api/v1/cotisation/<pk>/";
const ADD_REINSTATEMENT = "api/v1/cotisation/add-reinstatement/";

const LIST_REMOTE = "api/v1/member/list-remote/";
const LIST_REMOTE_MULTIPLE = "api/v1/cotisation/list-remote-multiple/";
const SET_INSTITUTION = "api/v1/member/pro-form/";

const EXPORT_PDF = "api/v1/member/generate-pdf/";
const EXPORT_MEMBER_CARDS = "api/v1/member/member-cards/";
const TRANSFER_MEMBER = "api/v1/member/<pk>/transfer/";

const GET_JOIN_NOW = "api/v1/onboarding/list/";
const ARCHIVE_JOIN_NOW = "api/v1/onboarding/<pk>/archive/";
const EXPORT_MEMBERS = "api/v1/member/member-export/";

export default {
    setInstitution(payload) {
        ApiService.setHeader();
        return ApiService.post(SET_INSTITUTION, payload).then((response) => {
            return response.data;
        });
    },
    getMembers(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_MEMBERS, payload).then((response) => {
            return response.data;
        });
    },
    getShortenMember(id) {
        return ApiService.get(GET_SHORTEN_MEMBER.replace("<pk>", id)).then(
            (response) => {
                return response.data;
            }
        );
    },
    listRemote(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_REMOTE, payload).then((response) => {
            return response.data;
        });
    },
    listRemoteMultiple(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_REMOTE_MULTIPLE, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    getSpecificMember(payload) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_MEMBER + payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    updateMember(memberId, payload) {
        ApiService.setHeader();
        return ApiService.post(
            UPDATE_MEMBER.replace("<pk>", memberId),
            payload
        ).then((response) => {
            return response.data;
        });
    },
    addMember(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_MEMBER, payload).then((response) => {
            return response;
        });
    },
    deleteMembers(payload) {
        ApiService.setHeader();
        return ApiService.post(DELETE_MEMBERS, payload).then((response) => {
            return response.data;
        });
    },
    archiveMembers(payload) {
        ApiService.setHeader();
        return ApiService.post(ARCHIVE_MEMBERS, payload).then((response) => {
            return response.data;
        });
    },
    getNationalities(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_NATIONALITIES, payload).then((response) => {
            return response.data;
        });
    },
    getBailliages() {
        ApiService.setHeader();
        return ApiService.get(GET_BAILLIAGES).then((response) => {
            return response.data;
        });
    },
    getSpecificBailliage(id: any) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_BAILLIAGE.replace("<pk>", id)).then(
            (response) => {
                return response.data;
            }
        );
    },
    getChildrenBailliages(id: any) {
        ApiService.setHeader();
        return ApiService.get(GET_CHILDREN_BAILLIAGES.replace("<pk>", id)).then(
            (response) => {
                return response.data;
            }
        );
    },
    getMultipleChildrenBailliages(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_CHILDREN_BAILLIAGES_MULTIPLE, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    listBailliages(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_BAILLIAGES, payload).then((response) => {
            return response.data;
        });
    },
    getGrades(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_GRADES, payload).then((response) => {
            return response.data;
        });
    },
    getFunctions(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_FUNCTIONS, payload).then((response) => {
            return response.data;
        });
    },
    getMaritalStatuses(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_MARITAL_STATUSES, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    getMedals(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_MEDALS, payload).then((response) => {
            return response.data;
        });
    },
    getCommanders(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_COMMANDERS, payload).then((response) => {
            return response.data;
        });
    },
    getEligibleCommanders(id) {
        ApiService.setHeader();
        return ApiService.get(GET_ELIGIBLE_COMMANDERS.replace("<pk>", id)).then(
            (response) => {
                return response.data;
            }
        );
    },
    getActivitySectors(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_ACTIVITY_SECTORS, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    getTitles(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_TITLES, payload).then((response) => {
            return response.data;
        });
    },
    getInstitutionTypes(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_INSTITUTION_TYPES, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    getDuesTypes(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_DUE_TYPES, payload).then((response) => {
            return response.data;
        });
    },
    getPriceCategories(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_PRICE_CATEGORIES, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    getFilteredPriceCategories(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_PRICE_CATEGORIES_FILTERED, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    getMemberDues(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_DUES, payload).then((response) => {
            return response.data;
        });
    },
    addDues(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_DUE, payload).then((response) => {
            return response.data;
        });
    },
    deleteDue(payload) {
        ApiService.setHeader();
        return ApiService.delete(MANAGE_DUE.replace("<pk>", payload)).then(
            (response) => {
                return response.data;
            }
        );
    },
    editDue(id, payload) {
        ApiService.setHeader();
        return ApiService.patch(MANAGE_DUE.replace("<pk>", id), payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    exportPDF(payload) {
        ApiService.setHeader();
        return ApiService.post(EXPORT_PDF, payload).then((response) => {
            return response.data;
        });
    },
    exportMemberCards(payload) {
        ApiService.setHeader();
        return ApiService.post(EXPORT_MEMBER_CARDS, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    addReinstatement(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_REINSTATEMENT, payload).then((response) => {
            return response.data;
        });
    },
    transferMember(id, payload) {
        ApiService.setHeader();
        return ApiService.post(
            TRANSFER_MEMBER.replace("<pk>", id),
            payload
        ).then((response) => {
            return response.data;
        });
    },
    getJoinNow(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_JOIN_NOW, payload).then((response) => {
            return response.data;
        });
    },
    declineJoinNow(id) {
        ApiService.setHeader();
        return ApiService.delete(ARCHIVE_JOIN_NOW.replace("<pk>", id)).then(
            (response) => {
                return response.data;
            }
        );
    },
    getSpecificDuesType(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_SPECIFIC_DUE_TYPES, payload).then(
            (response) => {
                return response.data;
            }
        );
    },
    getSpokenLanguages() {
        ApiService.setHeader();
        return ApiService.get(GET_SPOKEN_LANGUAGES).then((response) => {
            return response.data;
        });
    },
    validateMember(payload) {
        ApiService.setHeader();
        return ApiService.post(VALIDATE_MEMBER, payload).then((response) => {
            return response.data;
        });
    },
    sendMail(id) {
        ApiService.setHeader();
        return ApiService.get(SEND_PRO_MEMBER_EMAIL.replace("<pk>", id)).then(
            (response) => {
                return response.data;
            }
        );
    },
    exportMembers(payload) {
        ApiService.setHeader();
        return ApiService.post(EXPORT_MEMBERS, payload).then((response) => {
            return response.data;
        });
    },
};
