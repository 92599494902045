import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import authApi from "../../core/services/Authenticate";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    token: string;
}

export interface UserAuthInfo {
    errors: Array<string>;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = [];
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AUTH](userData) {
        this.isAuthenticated = true;
        this.user = userData.user;
        this.errors = [];
        JwtService.saveToken(userData.access);
        const token_infos = JwtService.parseToken(localStorage.getItem("id_token"));
        localStorage.setItem('perm_pos', token_infos.perm_position)
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
        localStorage.clear();
        console.log("Purge auth");
    }

    @Action
    [Actions.LOGIN](credentials) {
        return new Promise<void>((resolve, reject) => {
            authApi
                .login(credentials)
                .then((data) => {
                    this.context.commit(Mutations.SET_AUTH, data.data);
                    resolve(data.data)
                })
                .catch(({ error }) => {
                    this.context.commit(Mutations.SET_ERROR, error);
                    reject(error);
                });
        });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return new Promise<void>((resolve, reject) => {
            authApi
                .register(credentials)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return new Promise<void>((resolve, reject) => {
            authApi
                .forgetPassword(payload)
                .then(({ data }) => {
                    if (!data.success) {
                        this.context.commit(Mutations.SET_ERROR, data.error);
                        reject();
                    } else {
                        resolve();
                    }
                })
                .catch(({ error }) => {
                    this.context.commit(Mutations.SET_ERROR, error);
                    reject(error);
                });
        });
    }

    @Action
    [Actions.CHANGE_PASSWORD](payload) {
        return new Promise<void>((resolve, reject) => {
            authApi
                .changePassword(payload)
                .then(({ data }) => {
                    if (!data.success) {
                        this.context.commit(Mutations.SET_ERROR, data.error);
                        reject();
                    } else {
                        resolve();
                    }
                })
                .catch(({ error }) => {
                    this.context.commit(Mutations.SET_ERROR, error);
                    reject(error);
                });
        });
    }

    @Action
    [Actions.VERIFY_AUTH]() {
        return new Promise<void>((resolve, reject) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                resolve();
            } else {
                this.context.commit(Mutations.PURGE_AUTH);
                reject();
            }
        })
    }

    @Action
    [Actions.GET_USER]() {
        if (this.isAuthenticated) {
            ApiService.setHeader();
            return new Promise<void>((resolve, reject) => {
                authApi
                    .getUser()
                    .then(({ data }) => {
                        this.context.commit(Mutations.SET_USER, data.user);
                        resolve();
                    })
                    .catch(() => {
                        this.context.commit(Mutations.PURGE_AUTH);
                        reject();
                    });
            });
        }
    }

    @Action
    [Actions.UPDATE_USER](payload) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.post("update_user", payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_USER, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }
}
