<template>
    <router-view />
</template>

<style lang="scss">
//@use "~bootstrap-icons/font/bootstrap-icons.css";
// @import "~apexcharts/dist/apexcharts.css";
// Main demo style scss
@use "assets/sass/plugins";
@use "assets/sass/style";
@use "~@yaireo/tagify/src/tagify.scss";

@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
//@import "@element-plus/theme-chalk/dist/index.css";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "app",
    setup() {
        const store = useStore();
        const router = useRouter();

        onMounted(() => {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
        });

        return { store, router };
    },
    // created() {
    //     console.log("app created");

    //     axios.interceptors.response.use(
    //         (response) => {
    //             if (response.status === 401) {
    //                 //add your code
    //             }
    //             return response;
    //         },
    //         (error) => {
    //             if (error.response && error.response.data) {
    //                 //add your code
    //                 this.store.dispatch(Actions.LOGOUT);
    //                 this.router.push({ name: "sign-in" });

    //                 return Promise.reject(error.response.data);
    //             }
    //             return Promise.reject(error.message);
    //         }
    //     );
    // },
});
</script>
