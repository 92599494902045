import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/ApiResponse";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL =
            process.env.VUE_APP_API_URL;
        // console.log("ApiService -> init -> process.env.VUE_APP_API_URL", process.env.VUE_APP_API_URL);
        // ApiService.vueInstance.axios.defaults.baseURL = window.location.origin;
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
        delete axios.defaults.headers.common["X-CSCAPI-KEY"];
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setFormDataHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
        ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
            "multipart/form-data";
        delete axios.defaults.headers.common["X-CSCAPI-KEY"];
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios
            .get(resource, params)
            .catch((error) => {
                throw new Error(`[KT] ApiService ${error}`);
            });
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get(
        resource: string,
        slug = "" as string
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios
            .get(`${resource}/${slug}`)
            .catch((error) => {
                throw new Error(`[KT] ApiService ${error}`);
            });
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.post(`${resource}`, params);
    }

    /**
 * @description send the GET HTTP request
 * @param resource: string
 * @param slug: string
 * @returns Promise<AxiosResponse>
 */
    public static getT<T>(
        resource: string,
        slug = "" as string
    ): Promise<AxiosResponse<ApiResponse<T>>> {
        return ApiService.vueInstance.axios
            .get<ApiResponse<T>>(`${resource}/${slug}`)
            .catch((error) => {
                throw new Error(`[KT] ApiService ${error}`);
            });
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static postT<T>(
        resource: string,
        data: any
    ): Promise<AxiosResponse<ApiResponse<T>>> {
        return ApiService.vueInstance.axios.post<ApiResponse<T>>(`${resource}`, data);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(
        resource: string,
        slug: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    /**
     * @description Send the PATCH HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static patch(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.patch(`${resource}`, params);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.delete(resource).catch((error) => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
}

export default ApiService;
